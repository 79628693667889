<template>
  <router-view />
</template>
  

<script>
  export default {
    name: 'App',
  }
</script>
