import Vue from 'vue'
import Vuex from 'vuex'
import { config } from './config';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg',
    baseUrl: config.baseURL,
    drawer: null,
    autenticated:false,
    token: "",
    user:null,
    activeHistorialComprobantes:false,
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    setToken(state,token){
      state.token = token;
    },
    setUser(state,user){
      state.user = user;
    },
    setAutenticated(state,auth){
      state.autenticated = auth;
    },
    setActiveHistorialComprobantes(state,active){
      state.activeHistorialComprobantes = active
    }
  },
  actions: {

  },
  getters:{
    autenticated:(state) => {
      return state.autenticated;
    }
  }
})
